<template>
  <div class="admin-index">
        <navigationBar :routerList="this.$router.history.current.meta.breadcrumb" 
          @buttonClickEmit0="updateOrAddAdmin" 
          :buttonList="buttonList"/>
  	    <div>
            <el-form :inline="true" :model="filters" ref="filters">

                 <el-form-item prop = "name" label="用户姓名:">
                    <el-input v-model="filters.name" placeholder="用户姓名" size="mini"></el-input>
                </el-form-item>
                <el-form-item prop = "dept_id" label="所属部门:">
                    <el-select  v-model="filters.dept_id" clearable placeholder="所属部门" filterable size="mini">
                        <el-option
                            v-for="item in departmentList"
                            :key="item.id"
                            :label="item.dept_name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item> 
                <el-form-item>
                    <el-button type="primary"  @click="doSearch()" size="mini">查询</el-button>
                </el-form-item>
                <el-form-item style="margin-left: -1rem;">
                    <el-button type="info" size="mini" @click="resetForm('filters')">重置</el-button>
                </el-form-item>
                <span class="add-button" style="margin-top:5px;">
                </span>
            </el-form>
        </div>
        <!-- 列表信息 -->
        <!--<div style="color:#969AA6;font-size:0.7rem;font-weight:400;margin-bottom: 1.3rem;">共<span style="color:#3C92FF;">{{this.count}}</span>项</div>-->
        <el-table :data="adminData"  style="width: 100%">
            <el-table-column prop="idNo" label="序号" width="80"></el-table-column>
		        <el-table-column prop="user_name" label="用户名" ></el-table-column>
		        <el-table-column prop="name" label="用户姓名" width="150"></el-table-column>
            <el-table-column prop="dept_name" label="所属部门"  width="150"></el-table-column>
            <el-table-column prop="roleName" label="角色权限"  width="150"></el-table-column>
    		    <el-table-column prop="mobile" label="用户手机号" width="150"></el-table-column>
            <el-table-column prop="position" label="职位" width="150"></el-table-column>
            <el-table-column prop="lock_flag" label="锁定状态" width="100">
                <template slot-scope="scope">
                    <span @click="updateLockFlag(scope.row)">
                        <el-switch v-model="scope.row.lock_flag" :active-value="1" :inactive-value="0"  ></el-switch>
                    </span>
                </template> 
                
            </el-table-column>
    		    <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                    <el-button  size="mini" type="success" @click="updatePass(scope.row)">修改密码</el-button>
                    <el-button  size="mini" type="warning" @click="updateOrAddAdmin(scope.row)">修改</el-button>
                    <el-button  size="mini" type="danger" @click="deleteAdmin(scope.row)">删除</el-button>
                </template>
            </el-table-column>
	    </el-table>
	    <!-- 新增或编辑弹出 -->
	    <el-dialog :title="titleAdmin"  :visible.sync="dialogFormVisible" width="40%">
            <el-form ref="form" :model="form"  :rules="rules" :label-width="formLabelWidth">
                <el-form-item label="用户名" prop="user_name">

                    <el-input type="text"  :disabled="this.disabled" v-model="form.user_name" placeholder="请输入用户名" style="width: 80%;" ></el-input>
                </el-form-item>

                <el-form-item label="用户姓名" prop="name">
                    <el-input type="text"   v-model="form.name" placeholder="请输入用户姓名" style="width: 80%;"></el-input>
                </el-form-item>

                <el-form-item label="密码" prop="password" v-if="!disabled">
                    <el-input type="password"  :disabled="this.disabled" v-model="form.password" placeholder="请输入密码" style="width: 80%;"></el-input>
                </el-form-item>

                <el-form-item label="密码"  v-if="disabled">
                    <el-input type="password"  :disabled="this.disabled" v-model="form.password" placeholder="请输入密码" style="width: 80%;"></el-input>
                </el-form-item>

                <el-form-item label="手机号码" prop="mobile">  
                    <el-input  type="text"  v-model="form.mobile" placeholder="请输入手机号码" style="width: 80%;"></el-input>
                </el-form-item>

                <el-form-item label="邮箱" prop="email">  
                    <el-input  type="text"  v-model="form.email" placeholder="请输入邮箱" style="width: 80%;"></el-input>
                </el-form-item>

                <el-form-item label="职位" prop="position">
                    <el-input type="text"   v-model="form.position" placeholder="请输入职位" style="width: 80%;"></el-input>
                </el-form-item>
 
                <el-form-item label="所属部门" prop="dept_id">
                    <el-cascader
                    placeholder="请选择所属部门"
                    :options="this.deptList"
                    filterable
                    change-on-select
                    clearable
                    @change="changePDepartment"
                    v-model="defaultData"
                    style="width:80%">
                    </el-cascader>
                </el-form-item>
 
                <el-form-item label="可用状态">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">可用</el-radio>
                        <el-radio :label="0">不可用</el-radio>
                    </el-radio-group>
                </el-form-item>
                
                 

                <el-form-item label="所属角色名称">
                      <el-table :data="this.roleList"  border header-row-class-name="role-header-row"  style="width: 100%" ref="editTable"  @select="selsChange">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column property="id" label="ID"></el-table-column>
                        <el-table-column property="name" label="角色名称"></el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button size="mini" type="primary" @click="submitForm('form')">提 交</el-button>
                <el-button size="mini" @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="visiblePass" width="30%" >
            <el-form :model="formPass" status-icon :rules="ruleForm" ref="formPass" label-width="80px">
              <el-form-item label="重置密码" prop="pass">
                <el-input type="password" v-model="formPass.pass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="formPass.checkPass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item>
                <div style="float: right;">
                    <el-button size="mini" type="primary" @click="submitPass('form')">提 交</el-button>
                    <el-button size="mini" @click="visiblePass = false">取 消</el-button>
                </div>
              </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 分页 -->
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pageNo"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="count">
            </el-pagination>
        </div>
  </div>
</template>

<script>
import setting from '@/utils/config/index.js'
import navigationBar from '@/components/navigationBar'
import {findAdminPage,updateOrAddAdmin,deleteAdminId,getDeptInfoList,getDepartmentById,getDepartmentList,
    getAllRole,getAdminRoleByAdminId,updateAdminPassword,updateLockFlag} from '@/api/getUcenterData'
export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.formPass.checkPass !== '') {
            this.$refs.formPass.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.formPass.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var checkUserId = (rule, value, callback) => {
        if (value === '') {
          return callback();
        }
        setTimeout(() => {
          if (!Number.isInteger(value)) {
              callback(new Error('请输入数字值'));
          } else {
              callback();
          }
        }, 1000);
      };
      return {
      	 adminData: [],
      	 filters:{
      	 	name:'',
          dept_id:''
      	 },
      	 dialogFormVisible:false,
      	 form:{},
      	 formLabelWidth: '140px',
      	 titleAdmin:'',
      	 rules:{
            password: [
                { required: true, message: '请输入密码', trigger: 'blur' },
                { min: 8,max: 25,message: '长度在 8 到 25个字符'}, 
                {pattern: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,25}/, message: '只能输入8-25个,且必须有数字字母特殊字符组合'}
            ],
            name: [
                {required: true, message: '请输入用户名', trigger: 'blur'},
                { validator:this.checkUserName, trigger: 'blur'}
            ],
            user_name: [{required: true, message: '请输入用户名', trigger: 'blur'}],
            dept_id: [{required: true, message: '请选择所属部门', trigger: 'blur'}],
            mobile: [
                {pattern: /^((0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$)|(^((\(\d{3}\))|(\d{3}\-))?(1[23456789]\d{9}))$/, message: '请输入正确联系方式'}],
            email :[
                {
                     pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                     message:'请输入正确邮箱地址'
                }],
        },
        ruleForm: {
          pass: [
            { validator: validatePass, trigger: 'blur' },
            { min: 8,max: 25,message: '长度在 8 到 25个字符'}, 
            {pattern: /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,25}/, message: '只能输入8-25个,且必须有数字字母特殊字符组合'}
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ],
            password:[{required: true, message: '请输入密码'}]
        },
        disabled:false,
        deptList:[],
        defaultData:[],
        roleList:[],
        sels:[],
        visiblePass:false,
        pageNo:1,
        pageSize:15,
        count:0,
        formPass:{
            id:'',
            pass:'',
            checkPass:''
        },
        buttonList:[
          {
            backgroundColor:'#52D1AF',
            icon:'/static/img/add.png',
            text:'新增',
          }
          
        ],
        showList:true,
        categroyInfos:[],
        departmentList:[],
        tableDataSelections:[],//选中的表格数据
        dept_name:"",
      }
    },
    components:{
        navigationBar
    },
    created(){
        console.log( "setting.url.ossPath",setting.url.ossPath)
        console.log( "process.env.VUE_OSS_PATH",process.env)
      this.findAdminPage();
      this.getAllRole();
      this.getDepartmentList();
        
    },
    mounted(){

    },
    methods:{
        getDepartmentList(){

             getDepartmentList( ).then((resData)=>{
                 if(resData.resultCode==1){
                    let arr = resData.data;
                    arr.splice(0,0,{id:0,dept_name:'全部'});
                    this.departmentList = arr;
                }else{
                    this.$message({message: resData.resultMsg,type: 'error'});
                }
                
            });
        },

        //状态 
        formatterLockFlag:function(row){
            return row.lock_flag == 1 ? '已锁定' :row.lock_flag == 0 ? '未锁定': ''
        },
  

    	//列表或查询
	    findAdminPage:function(){
            let para = {
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                name:this.filters.name,
                dept_id:this.filters.dept_id,
            };
            findAdminPage(para).then((resData) => {
                console.log(resData,"resData")
                if(resData.resultCode==1){
                    this.adminData = resData.data.list;
                    this.count = resData.data.total;
                    for(var i = 0 ; i < this.adminData.length; i++){
                        this.adminData[i].idNo = (this.pageNo - 1) * this.pageSize + i +1;  
                    }
                }else{
                    this.$message({message: resData.resultMsg,type: 'error'});
                }
            },(err)=>{
                this.$message({
                    message: '数据请求失败',
                    type: 'error'
                });
            });
        },
        doSearch:function(){
            this.pageNo =1;
            this.findAdminPage();
        },
        //弹出修改密码框
        updatePass:function(data){
            this.visiblePass = true;
            this.formPass.id = data.id;
            this.formPass.pass = '';
            this.formPass.checkPass = ''; 
        },
        handleCurrentChange:function(pageNo){
            this.pageNo = pageNo;
            this.findAdminPage();
        },
        //保存新密码
        submitPass:function(){
            this.$refs.formPass.validate((valid) => {
                if (valid) {
                    this.$confirm('确认提交吗？', '提示', {}).then(() => {
                        let para = {
                            id:this.formPass.id,
                            password:this.formPass.pass,
                            oldPass:this.formPass.checkPass
                        };
                        updateAdminPassword(para).then((res) => {
                            if (res.resultCode == 1) {
                                this.$message({message: '修改成功',type: 'success'});
                                this.visiblePass=false;
                            } else {
                               this.$message({message: res.resultMsg ,type: 'error'});
                            }
                        });
                    });
                }
            });

        },
        //锁定状态
        updateLockFlag:function(row){
           let para = {
              id:row.id,
              lock_flag:row.lock_flag,
              failureNum:row.failureNum
           }
           updateLockFlag(para).then((res) => {
                if (res.resultCode == 1) {
                    this.$message({message: '解锁成功',type: 'success'});
                }else if (res.resultCode == 2) {
                    this.$message({message: '用户已锁定',type: 'success'});
                } else {
                   this.$message({message: '操作失败' ,type: 'error'});
                }
            });
        },
        //弹出编辑或新增窗口
        updateOrAddAdmin:function(data){
        	this.dialogFormVisible = true;
        	if(data == null){
                this.disabled = false;
                this.form = {
                    status:1,
                    name:"",
                    password:""
                }
                console.log("新增", this.form)
                this.defaultData = [];
                this.titleAdmin = '新增';
                //清除选中列表框
                this.$nextTick(()=>{
                    this.$refs.editTable.clearSelection();
                })
        	}
        	else{
                this.disabled = true;
                this.form = JSON.parse(JSON.stringify(data));
                this.titleAdmin = '编辑';
                this.getDepartmentById();
                this.getAdminRoleByAdminId(data.id);
        	}
            this.getDeptInfoList();
        },
        //编辑或新增信息
        submitForm(form) {
	        this.$refs[form].validate((valid) => {
	        	if (valid) {
                    var param = [];
                    var lawCertificate = 0;
                    var lawCertificateNo = '';
                    var cateValue = [];
                     
	        		this.$confirm('确认提交吗？', '提示', {}).then(() => {
		        		let para ={
                            id:this.form.id,
                            user_name: this.form.user_name,
                            name: this.form.name,
                            email: this.form.email,
                            position: this.form.position,
                            mobile:this.form.mobile,
                            status:this.form.status,
                            password:this.form.password,
                            dept_id:this.form.dept_id,
                            lawCertificate:lawCertificate,
                            lawCertificateNo:lawCertificateNo,
                            roles:this.sels,
                            dept_name:this.dept_name,
	                    };
	                    updateOrAddAdmin(para).then((res) => {
	                        if (res.resultCode == 1) {
	                            this.$message({message: '编辑或新增成功',type: 'success'});
	                            this.dialogFormVisible=false;
	                        } else {
	                           this.$message({message: res.resultMsg ,type: 'error'});
	                        }
	                        this.findAdminPage();
	                    });
	                });
	            }
	        });
        },
        //删除用户信息
        deleteAdmin:function(row){
            if(row.user_name=="admin"){
                 this.$message({  type: 'error',
                        message: '系统管理员账号不能删除！！！'
                    });
            }else{
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    type: 'warning'
                }).then(() => {
                    let id = row.id;
                    deleteAdminId({id}).then((res) => {
                        if (res.resultCode == 1) {
                            this.$message({message: '删除成功',type: 'success'});
                            this.dialogFormVisible=false;
                        } else {
                           this.$message({message: res.resultMsg ,type: 'error'});
                        }
                        this.findAdminPage();
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            }
        	
        },
        //角色列表信息
        getAllRole:function(){
            let param = {
                pageNo: 1,
                pageSize: 1000,
            };
            getAllRole(param).then((resData)=>{
                 if(resData.resultCode==1){
                    this.roleList = resData.data.list
                }else{
                    this.$message({message: resData.resultMsg,type: 'error'});
                }
            })
        },
        //所属角色列表选择
        selsChange: function (sels,row) {
            this.$refs.editTable.clearSelection();
            this.$refs.editTable.toggleRowSelection(row);
            this.sels = [];
            this.sels.push(row);
        },
        //选中信息
        getAdminRoleByAdminId:function(id){ 
            let para = {
                user_id : id
            };
            this.$nextTick(()=>{
                this.$refs.editTable.clearSelection();
            })
            getAdminRoleByAdminId(para).then((resData)=>{
                 console.log("getAllRole",resData)
                    if(resData.resultCode==1){
                        var res = resData.data
                        if(res){
                            for(let i = 0; i < this.roleList.length;i++){
                                if(res == this.roleList[i].id){
                                    this.$refs.editTable.toggleRowSelection(this.roleList[i]);
                                    this.sels.push(this.roleList[i]);
                                }
                            }
                        }      
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }
                      
            });
        },
        //选择部门得到dept_id
        changePDepartment:function(value){
            this.form.dept_id = value[value.length - 1];
            this.$nextTick(()=>{
              getDepartmentById({id :this.form.dept_id}).then(resData=>{
                  if(resData.resultCode==1){
                       let res = resData.data;
                       this.dept_name = res.dept_name;
                  }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }
                    
              })
            })
        },
        //部门列表信息
        getDeptInfoList: function () {
            getDeptInfoList( ).then(resData=>{
                   if(resData.resultCode==1){
                            let res = resData.data;
                           let farr = [];
                            let sarr = [];
                            let tarr = [];
                            if(res.length > 0){
                                let resData = res;
                                for(var i = 0 ; i < resData.length; i++){
                                    if(!farr[resData[i].fId]){
                                        farr[resData[i].fId] = {label:resData[i].fDeptName,value:resData[i].fId}
                                    }
                                    if(!sarr[resData[i].sId]){
                                        sarr[resData[i].sId] = {label:resData[i].sDeptName,value:resData[i].sId,parentId:resData[i].sParentId}
                                    }
                                    if(!tarr[resData[i].tId]){
                                        tarr[resData[i].tId] = {label:resData[i].tDeptName,value:resData[i].tId,parentId:resData[i].tParentId}
                                    }
                                }
                                for(var i = 0 ; i < tarr.length ; i++){
                                    if(tarr[i]){
                                        if(!sarr[tarr[i].parentId].children){
                                            sarr[tarr[i].parentId].children = [];
                                        }
                                        sarr[tarr[i].parentId].children.push(tarr[i]);
                                    }
                                }
                                for(var i = 0 ; i < sarr.length ; i++){
                                    if(sarr[i]){
                                        if(!farr[sarr[i].parentId].children){
                                            farr[sarr[i].parentId].children = [];
                                        }
                                        farr[sarr[i].parentId].children.push(sarr[i]);
                                    }
                                }
                                this.deptList =[];
                                for(var i = 0 ; i < farr.length; i++){
                                    if(farr[i]){
                                        this.deptList.push(farr[i]);
                                    }
                                }
                            }
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

            })
        },
        //处理点击编辑进来后的默认值
        getDepartmentById(){
            let para = {id :this.form.dept_id};  
            return new Promise((resolve)=>{
            getDepartmentById(para).then(resData=>{

                    if(resData.resultCode==1){
                            let res = resData.data;
                            if(res){
                                this.form.dept_id = res.id;
                                this.defaultData = res.id;
                            }
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

                 })
            })
        },
        //重置
        resetForm(filters) {
            this.$refs[filters].resetFields();
            this.findAdminPage();
        },
    }
  }
</script>
<style lang="scss">
.admin-index{
    .el-table thead {
        color: #21293B;
        font-size: 0.8rem;
    }
    .el-table th, .el-table thead {
        background-color: #E9ECEE;
        height: 3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
    .paging{
       font-size: 0.8rem; 
       display: inline-block;
    }
    .el-pagination {
      display: inline-block
    }
    .img{
      width:1rem;
      height:1rem;
    }


   .el-table thead {
        font-size: 0.8rem;
    }
    .el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title{
        font-weight: bold;
    }
    .el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
        padding-left: 1rem;
    }
    .el-form--inline .el-form-item {
        margin-right: 1.5rem;
    }
}

</style>
